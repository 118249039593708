import { Routes } from '@angular/router';

export const appRoutes : Routes = [
  { path: '', loadChildren: () => import('./views/sitemule/solutions/solutions.module').then((mod) => mod.SitemuleSolutionsModule), },
  { path: 'persons-contact', loadChildren: () => import('./views/sitemule/persons-contact/contact.module').then((mod) => mod.PersonsContactModule), },
  {path: 'business-first-then-technology', loadChildren:() => import('./views/sitemule/concepts/concept.module').then((mod)=>mod.SitemuleConceptModule),},
  {
    path: 'download',
    loadChildren: () =>
      import('./views/sitemule/download/download.module').then(
        (mod) => mod.SitemuleDownloadModule
      ),
  },
  { path: '**', loadChildren: () => import('./views/404/404.module').then((mod) => mod.NotFoundModule), },
];
